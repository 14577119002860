import {ApiService} from '@/services/ApiService'

const URL = '/api/locales'

class LocaleService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
}

export default new LocaleService()