<template v-if="formActive">
    <div class="row">
        <label for="edit-locale-name" class="col-sm-4 col-form-label py-1">Name of language<br>(in this language)</label>
        <div class="col-sm-8">
            <input v-model="locales.locale_name" class="form-control form-control-sm mt-3 locale-input" id="edit-locale-name" @change="setValid"/>
        </div>
    </div>
    <div class="row" v-if="isNew">
        <label for="edit-id" class="col-sm-4 col-form-label pt-1 pb-2">Language code<br>(ISO 639-1)</label>
        <div class="col-sm-8">
            <input v-model="locales.id" class="form-control form-control-sm locale-input" id="edit-id" @change="setValid"/>
        </div>
    </div>
    <div class="row" v-for="(categories, category) in locales" :key="category">
        <div v-if="category != 'id' && category != 'locale_name'">
            <h4 class="text-capitalize mt-3 mb-2">{{ removeCamelCase(category) }}</h4>
            <div class="row" v-for="(value, key) in categories" :key="key">
                <label :for="'edit-'+key" class="col-sm-6 col-form-label pt-1 pb-2">{{ value['from'] }}</label>
                <div class="col-sm-6">
                    <input v-model="locales[category][key]['to']" class="form-control form-control-sm locale-input" :id="'edit-'+key" @change="setValid"/>
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
import localesService from '@/services/LocaleService.js';
export default {
    name: 'LocaleForm',
    props: ['itemData'],
    data() {
        return {
            locales: {},
            submitted: false,
            loading: false,
            invalid: {},
            itemService: localesService,
            isNew: false,
            formActive: false
        }
    },
    watch: {
        itemData: function (val) {
            this.locales = val    
        },
    },
    methods: {
        active(value){
            this.formActive = value;
        },
        validate(){
            let valid = true;
            let message = "";
            if (this.locales.id.length != 2){
                valid = false;
                message = "The language code must be exactly 2 characters long";
            }
            document.querySelectorAll(".locale-input").forEach(el => {
                if(!el.value){
                    valid = false;
                    message = this.$t('errors.empty_fields');
                    el.classList.add('is-invalid');
                }
            })
            if(!valid) alert(message);
            return valid;
        },
        getData() {
            this.itemService.show('template').then(response => {
                this.itemLoading = false;
                this.isNew = true;
                this.item = response.data;
                this.item.locale_name = "";
                this.item.id = "";
                for (const key in this.item){
                    if(typeof this.item[key] !== 'string'){
                        for (const key2 in this.item[key]){
                            this.item[key][key2].to = 'test';
                        }
                    }
                }
                this.locales = this.item;
            }).catch(error => {
                this.toastError(error)
                console.log('error', error) // create notification-toaster for user error
            })
            return this.locales;
        },
        setData(data) {
            this.locales = data
        },
        reset() {
            this.locales = {},
            this.loading = false
            this.invalid = {}
        },
        setValid(e){
            let field = e.target;
            let isValid = field.id == 'edit-id' ? field.value.length == 2 : field.value;
            if(isValid) {
                field.classList.add('is-valid');
                field.classList.remove('is-invalid');
            }
            else {
                field.classList.remove('is-valid');
            }
        },
        removeCamelCase(str){
            return str.replace('_', ' ');
        }
    },
    mounted () {
        this.reset()
        
    }
}
</script>
