<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'key'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :showDropdown="false"
        :showAddButton="false"
    >
        
        <template #columns>
            <Column :field="$i18n.locale" :header="$t('overview.language')"></Column>
            <Column field="id" :header="$t('overview.code')"></Column>
        </template>

        <template #form>
            <LocaleForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import LocaleForm from '@/components/locale/LocaleForm.vue';
import localesService from '@/services/LocaleService.js';

export default {
    components: {
        Overview,
        Column,
        LocaleForm
    },
    data() {
        return {
            item: {},
            itemService: localesService,
            itemFormRef: null
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>